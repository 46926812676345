.notFoundContainer {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.noResultsHeader, .noResultsText {
    margin: 0 auto 0 auto;
}

.noResultsHeader {
    font-size: 2rem;
    position: relative;
    bottom: 150px;
}

.noResultsText {
    font-size: 1rem;
    position: relative;
    bottom: 100px;
}