/* List View */
/* Title of card */
.ant-card-meta-title{
    white-space: normal;
}

/* Summary */
/* The svg arrow */
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    left: 0px;
}
/* Title */
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding:.5% 0 .4% 20px;
}
/* Content - Summary */
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    padding:.5% 0 5%;

}

.sectionHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.viewHeader {
    font-size: 2rem;
    padding: 2rem 0 2rem 2rem;
}

.tabs {
    padding: 1rem 4.5rem 2rem 0;
}

.docCard {
    width: 300px;
    margin-bottom: 17%;
    border: 1px solid #e7e4e0;

    &:hover {
        border: 1px solid #1890ff;
    }
}

.searchPageHeader {
    font-size: 2rem;
    position: absolute;
    top: 112px;
    left: 33px;
}

.searchbarText {
    padding-bottom: 15px;
    font-size: 22px;
    margin-top: -150px;
}

.searchbarText2 {
    font-size: 22px;
    padding-bottom: 50px;
}

.bookmarkIcon {
    position: absolute;
    right: -5px;
    top: -10px;
    transition: 0.3s;
    width: 50px;

    &:hover {
        cursor: pointer;
        width: 56px;
        right: -8px;
        top: -13px
    }
}

.bookmarkIconList {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 50px;
    transition: 0.3s;

    &:hover {
        cursor: pointer;
        width: 55px;
        top: 4px
    }
}

.downloadIcon {
    right: 40px;
    position: absolute;
    transition: 0.3s;
    width: 18px;

    &:hover {
        cursor: pointer;
        width: 21px;
        right: 37px;
        top: -1px
    }
}

.downloadIconList {
    right: 50px;
    top: 14px;
    position: absolute;
    transition: 0.3s;
    width: 18px;

    &:hover {
        cursor: pointer;
        width: 21px;
        top: 13px
    }
}