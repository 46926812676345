.header_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #dfe7e9;
  height: 65px;
  padding: 0;
}

.header_img {
  max-width: 260px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  left: 1.8%;
}

.search_bar {
  width: 40%;
  position: absolute;
  right: 30vw;
}

.navbar {
  position: fixed;
  width: 100%;
  transition: top ease-in 0.2s;
  z-index: 9999;
}

.navButtons {
  margin: 2rem;
}